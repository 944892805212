import styled from 'styled-components';

type StyledWrapperProps = {
  position: 'Left' | 'Center';
};

type StyledImageProps = {
  size: 'Small' | 'Medium' | 'Large';
};

export const StyledImageWrapper = styled.div<StyledImageProps>`
  width: ${props => {
    return props.size === 'Small' ? '50%' : props.size === 'Medium' ? '75%' : '100%';
  }};

  @media (max-width: 900px) {
    width: ${props => (props.size === 'Large' ? '100%' : '75%')};
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const StyledWrapper = styled.div<StyledWrapperProps>`
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: ${props => (props.position === 'Left' ? 'flex-start' : 'center')};
`;
